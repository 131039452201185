import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { Routes, Route, useLocation } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header";
import Footer from "./components/Footer";
import TrackPageViews from "./components/TrackPageViews";
import HomePage from "./pages/HomePage";
import BlogPost from "./pages/BlogPost";
import ContactPage from "./pages/ContactPage";
import AboutPage from "./pages/AboutPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsConditions from "./pages/TermsConditions";
import Blog from "./pages/Blog";
import SearchResult from "./pages/SearchResult";
import RedirectPage from "./pages/RedirectPage";
import PageNotFound from "./pages/PageNotFound";

const App: React.FC = () => {
  const location = useLocation();
  const isRedirectPage = location.pathname === "/redirect";
  const gaMId = process.env.REACT_APP_GA_Measurement_ID as string;

  useEffect(() => {
    // Initialize GA only once
    ReactGA.initialize(gaMId);
  }, []);

  return (
    <HelmetProvider>
      {/* Track pageviews for every route change */}
      <TrackPageViews />
      <div className="flex flex-col min-h-screen bg-gray-50">
        {/* Header Section with full-width background and primary color */}
        {!isRedirectPage && (
          <header className="w-full bg-primary">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Header />
            </div>
          </header>
        )}

        {/* Routes for different pages */}
        <main className="flex-grow bg-gray-50">
          <Routes>
            {/* Home Page Content */}
            <Route path="/" element={<HomePage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/a-propos" element={<AboutPage />} />
            <Route
              path="/politique-de-confidentialite"
              element={<PrivacyPolicy />}
            />
            <Route path="/conditions-generales" element={<TermsConditions />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/sejours/:destination" element={<SearchResult />} />

            {/* Internal redirect url */}
            <Route path="/redirect" element={<RedirectPage />} />

            {/* Catch-all route for 404 Page */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </main>

        {/* Footer Section */}
        {!isRedirectPage && (
          <footer className="w-full bg-gray-800 py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
              <Footer />
            </div>
          </footer>
        )}
      </div>
    </HelmetProvider>
  );
};

export default App;
