import React from "react";
import { Helmet } from "react-helmet-async";

const PrivacyPolicy: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>Politique de confidentialité | SenReservations</title>
        <meta
          name="description"
          content="Votre vie privée est importante pour nous. Découvrez comment SenReservations protège vos informations personnelles et gère vos données en toute sécurité."
        />
        <link
          rel="canonical"
          href={`${baseUrl}/politique-de-confidentialite`}
        />
        <link
          rel="alternate"
          href={`${baseUrl}/politique-de-confidentialite`}
          hrefLang="fr-SN"
        />
      </Helmet>
      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-20">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Politique de Confidentialité
        </h2>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            1. Introduction
          </h3>
          <p className="text-gray-600">
            Chez SenReservations, nous nous engageons à protéger la
            confidentialité de vos informations. Cette politique de
            confidentialité explique comment nous collectons, utilisons et
            protégeons vos informations lorsque vous utilisez notre plateforme.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            2. Collecte des Données
          </h3>
          <p className="text-gray-600">
            Nous pouvons collecter différentes informations pour fournir et
            améliorer nos services :
          </p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>
              <strong>Informations Personnelles</strong> : telles que nom,
              adresse email, et coordonnées lorsque vous vous inscrivez ou nous
              contactez.
            </li>
            <li>
              <strong>Données d'Utilisation</strong> : informations sur vos
              interactions avec notre site, comme les pages visitées, les clics,
              et les préférences.
            </li>
            <li>
              <strong>Cookies et Technologies de Suivi</strong> : nous utilisons
              des cookies et des outils d'analyse pour comprendre l'utilisation
              de notre site et améliorer votre expérience.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            3. Utilisation des Données
          </h3>
          <p className="text-gray-600">
            Nous utilisons vos données pour les raisons suivantes :
          </p>
          <ul className="list-disc list-inside text-gray-600 ml-4">
            <li>
              Améliorer les fonctionnalités et l'expérience utilisateur de notre
              plateforme.
            </li>
            <li>Vous fournir un support client et répondre à vos questions.</li>
            <li>
              Personnaliser votre expérience et vous présenter des informations
              pertinentes.
            </li>
          </ul>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            4. Partage des Données avec des Tiers
          </h3>
          <p className="text-gray-600">
            Vos données peuvent être partagées avec des partenaires tiers de
            confiance, tels que Google Analytics, pour des raisons de suivi et
            d'amélioration de notre site. Nous ne vendons pas vos informations
            personnelles et partageons vos données uniquement dans des cas
            nécessaires pour le service.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            5. Sécurité des Données
          </h3>
          <p className="text-gray-600">
            Nous prenons des mesures de sécurité appropriées pour protéger vos
            informations. Cependant, aucune méthode de transmission sur Internet
            n'est sécurisée à 100 %. Nous nous efforçons de protéger vos
            données, mais nous ne pouvons garantir une sécurité absolue.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">6. Vos Droits</h3>
          <p className="text-gray-600">
            Vous avez le droit d'accéder, de mettre à jour ou de supprimer vos
            informations personnelles. Vous pouvez également refuser certains
            types de collecte de données, comme les cookies ou les
            communications promotionnelles.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            7. Conservation des Données
          </h3>
          <p className="text-gray-600">
            Nous conservons vos informations aussi longtemps que nécessaire pour
            fournir nos services et pour nous conformer aux lois en vigueur. Les
            données relatives aux cookies peuvent être conservées pour une durée
            limitée.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            8. Modifications de la Politique de Confidentialité
          </h3>
          <p className="text-gray-600">
            Nous pouvons mettre à jour cette politique de confidentialité de
            temps en temps. Nous vous informerons de toute modification
            importante et nous vous encourageons à consulter cette page
            régulièrement pour rester informé.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">9. Contact</h3>
          <p className="text-gray-600">
            Si vous avez des questions concernant cette politique de
            confidentialité, veuillez nous contacter par email à :
            <a
              href="mailto:info@senreservations.com"
              className="text-blue-600 hover:underline ml-1"
            >
              info@senreservations.com
            </a>
            .
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
