import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Header: React.FC = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle the menu open/close
  const toggleMenu = () => setMenuOpen(!menuOpen);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const navigate = useNavigate();

  const handleLogoClick = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault(); // Prevent the default link navigation
    navigate("/", { replace: true });
    window.location.reload();
  };

  return (
    <header className="w-full bg-primary text-white fixed top-0 left-0 right-0 z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center py-4">
        <h1 className="text-xl font-bold">
          <Link to="/" onClick={handleLogoClick}>
            SenReservations.com
          </Link>
        </h1>
        <button onClick={toggleMenu} className="md:hidden text-white">
          {menuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </button>
        <nav className="hidden md:flex space-x-6">
          <Link to="/a-propos" className="hover:text-gray-200 transition">
            À propos
          </Link>
          <Link to="/contact" className="hover:text-gray-200 transition">
            Contact
          </Link>
        </nav>
      </div>

      {/* Mobile Menu */}
      <div
        className={`fixed top-0 left-0 w-3/4 h-full bg-primary transform ${
          menuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out z-40`}
      >
        <div className="flex justify-between items-center px-4 py-4 border-b border-gray-300">
          <h1 className="text-lg font-bold text-white">
            <Link to="/" onClick={closeMenu}>
              SenReservations.com
            </Link>
          </h1>
          <button onClick={toggleMenu} className="text-white">
            <FaTimes />
          </button>
        </div>
        {menuOpen && (
          <nav className="flex flex-col mt-6 space-y-4 px-4">
            <Link
              to="/a-propos"
              onClick={closeMenu}
              className="hover:text-gray-200 transition"
            >
              À propos
            </Link>
            <Link
              to="/contact"
              onClick={closeMenu}
              className="hover:text-gray-200 transition"
            >
              Contact
            </Link>
          </nav>
        )}
      </div>

      {/* Overlay */}
      {menuOpen && (
        <div
          onClick={toggleMenu}
          className="fixed inset-0 bg-black opacity-50 z-30"
        />
      )}
    </header>
  );
};

export default Header;
