import React from "react";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  return (
    <footer className="bg-gray-800 text-white py-6">
      <div className="container mx-auto text-center">
        {/* Links Section */}
        <div className="mb-4 space-x-4">
          <Link
            to="/politique-de-confidentialite"
            className="text-gray-400 hover:text-white transition"
          >
            Politique de Confidentialité
          </Link>
          <Link
            to="/conditions-generales"
            className="text-gray-400 hover:text-white transition"
          >
            Conditions Générales
          </Link>
          <Link
            to="/blog"
            className="text-gray-400 hover:text-white transition"
          >
            Blog
          </Link>
        </div>
        <p>
          &copy; {new Date().getFullYear()} SenReservations.com. Tous droits
          réservés.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
