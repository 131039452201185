import React from "react";
import { Helmet } from "react-helmet-async";

const ContactPage: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>Contactez-nous | SenReservations</title>
        <meta
          name="description"
          content="Avez-vous des questions sur nos services ? Contactez l'équipe SenReservations pour toute demande concernant les réservations ou le fonctionnement de la plateforme."
        />
        <link rel="canonical" href={`${baseUrl}/contact`} />
        <link rel="alternate" href={`${baseUrl}/contact`} hrefLang="fr-SN" />
      </Helmet>
      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-20">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Contactez-nous
        </h2>

        <p className="text-gray-600 mb-4">
          <strong>Veuillez noter :</strong> pour toute question ou modification
          liée à votre réservation, contactez directement le prestataire de
          réservation auprès duquel vous avez effectué votre réservation.
          SenReservations.com ne peut accéder ni modifier vos réservations
          effectuées via ces plateformes.
        </p>

        <p className="text-gray-600">
          Pour toute autre demande d'information ou assistance générale, vous
          pouvez nous joindre par email à :
          <a
            href="mailto:info@senreservations.com"
            className="text-blue-600 hover:underline ml-1"
          >
            info@senreservations.com
          </a>
        </p>
      </div>
    </>
  );
};

export default ContactPage;
