import React from "react";
import ReactGA from "react-ga4";
import { capitalizeDestinationName } from "../utils/stringUtils";

interface PartnerLinkProps {
  destination?: string;
}

const PartnerLink: React.FC<PartnerLinkProps> = ({ destination }) => {
  const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  // Define links for each destination
  const destinationLinks: { [key: string]: string } = {
    dakar: process.env.REACT_APP_EXPEDIA_DAKAR_LINK || "",
    paris: process.env.REACT_APP_EXPEDIA_Paris_LINK || "",
    saly: process.env.REACT_APP_EXPEDIA_Saly_LINK || "",
    "saint-louis": process.env.REACT_APP_EXPEDIA_Saint_Louis_LINK || "",
    "cap skirring": process.env.REACT_APP_EXPEDIA_Cap_Skirring_LINK || "",
    dubai: process.env.REACT_APP_EXPEDIA_Dubai_LINK || "",
    marrakech: process.env.REACT_APP_EXPEDIA_Marrakech_LINK || "",
    istanbul: process.env.REACT_APP_EXPEDIA_Istanbul_LINK || "",
  };

  // Get the link for the selected destination, defaulting to a general link if none is found
  const expediaLink =
    destinationLinks[destination || ""] ||
    process.env.REACT_APP_EXPEDIA_SEN_DEEP_LINK;

  const gaSendPartnerLinkClickEvent = () => {
    ReactGA.event({
      category: "Partner Link",
      action: "Click",
      label: "Expedia",
    });
  };

  return (
    <div className="p-6 bg-white border border-gray-300 rounded-md shadow-md text-center mt-6">
      <div className="flex flex-wrap justify-center gap-4">
        <a
          href={`/redirect?provider=expedia&to=${expediaLink}`}
          onClick={gaSendPartnerLinkClickEvent}
          target="_blank"
          rel="noopener noreferrer"
          className="flex flex-col items-center"
        >
          <img
            src={`${baseImageUrl}/logos/expedia-logo.svg`}
            alt="Expedia"
            className="h-12 w-auto mb-2"
          />
          <span className="text-blue-600 hover:underline">
            Trouvez les meilleures offres{" "}
            {destination
              ? `pour ${capitalizeDestinationName(destination)}`
              : ""}{" "}
            sur Expedia
          </span>
        </a>
      </div>
    </div>
  );
};

export default PartnerLink;
