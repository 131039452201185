import React from "react";
import { Helmet } from "react-helmet-async";

const TermsConditions: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>Conditions générales | SenReservations</title>
        <meta
          name="description"
          content="Prenez connaissance des conditions générales d'utilisation de SenReservations. Découvrez vos droits, vos responsabilités, et nos engagements en matière de services de réservation."
        />
        <link rel="canonical" href={`${baseUrl}/conditions-generales`} />
        <link
          rel="alternate"
          href={`${baseUrl}/conditions-generales`}
          hrefLang="fr-SN"
        />
      </Helmet>
      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-20">
        <h2 className="text-3xl font-bold text-gray-800 mb-6">
          Conditions Générales
        </h2>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            1. Introduction
          </h3>
          <p className="text-gray-600">
            Bienvenue sur SenReservations. En accédant à notre plateforme, vous
            acceptez de respecter les présentes Conditions Générales
            d'Utilisation. Si vous n'acceptez pas ces conditions, nous vous
            demandons de quitter le site immédiatement.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            2. Utilisation de la Plateforme
          </h3>
          <p className="text-gray-600">
            SenReservations propose une plateforme de recherche et de
            comparaison pour les séjours, les vols et autres services de voyage.
            La plateforme est exclusivement destinée à un usage personnel et non
            commercial, et tout usage non autorisé est strictement interdit, y
            compris le scraping de données, le piratage, et l'utilisation
            frauduleuse.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            3. Réservations et Liens Tiers
          </h3>
          <p className="text-gray-600">
            SenReservations facilite votre recherche de séjours, vols et autres
            services de voyage en vous redirigeant vers des partenaires de
            réservation tiers. Toute réservation effectuée après la redirection
            est strictement la responsabilité de l'utilisateur. En cliquant sur
            un lien pour un partenaire tiers, vous acceptez qu'il est de votre
            entière responsabilité de procéder à la réservation directement avec
            ce partenaire.
          </p>
          <p className="text-gray-600">
            SenReservations n'est pas responsable des activités, réservations ou
            transactions effectuées sur les sites des partenaires tiers. Toute
            interaction ou transaction que vous effectuez sur un site tiers est
            sous votre propre responsabilité, et SenReservations ne pourra être
            tenu responsable des problèmes ou des pertes liées à ces activités.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            4. Comptes Utilisateurs et Responsabilités
          </h3>
          <p className="text-gray-600">
            Si vous créez un compte sur SenReservations, vous êtes responsable
            de la confidentialité et de la sécurité de vos informations. Vous
            acceptez de maintenir des informations exactes et de ne pas partager
            vos identifiants de connexion. SenReservations ne peut être tenu
            responsable de toute perte ou dommage résultant de la mauvaise
            gestion de votre compte.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            5. Propriété Intellectuelle
          </h3>
          <p className="text-gray-600">
            Le contenu de SenReservations, y compris, mais sans s'y limiter, les
            textes, graphiques, logos et images, est protégé par des lois de
            propriété intellectuelle. Toute reproduction, distribution ou
            modification non autorisée est interdite sans consentement écrit
            préalable.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            6. Décharge de Responsabilité
          </h3>
          <p className="text-gray-600">
            SenReservations fournit les informations "en l'état" et ne garantit
            pas leur exactitude, disponibilité ou exhaustivité. En utilisant
            cette plateforme, vous acceptez que SenReservations ne peut être
            tenu responsable de toute perte, dommage, ou inconvénient résultant
            de l'utilisation de notre site ou des réservations effectuées via
            des prestataires tiers. Si vous n'êtes pas d'accord avec cette
            décharge de responsabilité, veuillez quitter le site immédiatement.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">
            7. Modifications des Conditions
          </h3>
          <p className="text-gray-600">
            SenReservations se réserve le droit de modifier ces Conditions
            Générales à tout moment. Nous vous encourageons à consulter cette
            page régulièrement pour rester informé des éventuelles mises à jour.
          </p>
        </section>

        <section className="mb-6">
          <h3 className="text-xl font-semibold text-gray-800">8. Contact</h3>
          <p className="text-gray-600">
            Pour toute question ou demande d'information concernant ces
            conditions, veuillez nous contacter par email à :
            <a
              href="mailto:info@senreservations.com"
              className="text-blue-600 hover:underline ml-1"
            >
              info@senreservations.com
            </a>
            .
          </p>
        </section>
      </div>
    </>
  );
};

export default TermsConditions;
