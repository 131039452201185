const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

// Define the structure of destination data
export interface BlogItem {
  id: number;
  date: string;
  title: string;
  shortTitle: string;
  excerpt: string;
  imageUrl: string;
  slug: string;
}
//IMPORTANT: Ensure slug are always in lowercase
export const blogItems: BlogItem[] = [
  {
    id: 1,
    date: "1er novembre 2024",
    title: "Les Meilleures Destinations au Sénégal pour vos Vacances en 2025",
    shortTitle: "Les Meilleures Destinations au Sénégal",
    excerpt:
      "Découvrez les lieux incontournables au Sénégal, y compris Dakar, Saly, Saint-Louis, et bien d'autres, pour une aventure inoubliable.",
    imageUrl: `${baseImageUrl}/blog/goree-island.jpg`,
    slug: "destinations-senegal-2025",
  },
  {
    id: 2,
    date: "5 novembre 2024",
    title: "Conseils de Voyage pour une Expérience Inoubliable au Sénégal",
    shortTitle: "Conseils de Voyage au Sénégal",
    excerpt:
      "Préparez-vous pour votre voyage avec ces conseils essentiels pour visiter le Sénégal en toute sérénité.",
    imageUrl: `${baseImageUrl}/blog/conseils-voyage.jpg`,
    slug: "conseils-voyage-senegal",
  },
  {
    id: 3,
    date: "10 novembre 2024",
    title: "Les Meilleurs Hébergements au Sénégal pour Tous les Budgets",
    shortTitle: "Les Meilleurs Hébergements au Sénégal",
    excerpt:
      "Explorez une sélection d'hôtels, maisons d'hôtes, et autres options d'hébergement adaptés à tous les budgets.",
    imageUrl: `${baseImageUrl}/blog/meilleurs-hebergements-senegal.jpg`,
    slug: "meilleurs-hebergements-senegal",
  },
];
