import React from "react";
import { Link } from "react-router-dom";

const PageNotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen text-center p-8 bg-gray-100">
      <h1 className="text-4xl font-bold text-gray-800 mb-4">
        404 - Page Not Found
      </h1>
      <p className="text-lg text-gray-600 mb-8">
        Oops! La page que vous recherchez n'existe pas.
      </p>
      <Link
        to="/"
        className="bg-blue-600 text-white font-bold py-2 px-6 rounded-md hover:bg-blue-700 transition-all"
      >
        Retour à la page d'accueil
      </Link>
    </div>
  );
};

export default PageNotFound;
