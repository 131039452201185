import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { BlogProvider, useBlogItems } from "../dataProviders/BlogProvider";

const BlogContent: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const { blogItems } = useBlogItems();

  const blogItemSchema = blogItems.map((blogItem) => ({
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: blogItem.title,
    image: blogItem.imageUrl,
    author: {
      "@type": "Person",
      name: "SenReservations",
    },
    publisher: {
      "@type": "Organization",
      name: "SenReservations",
    },
    datePublished: blogItem.date,
    description: blogItem.excerpt,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${baseUrl}/blog/${blogItem.slug}`,
    },
  }));

  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>
          Explorez Nos Conseils de Voyage et Inspirations - SenReservations Blog
        </title>
        <meta
          name="description"
          content="Découvrez des conseils de voyage, des idées d'itinéraires, et des inspirations pour planifier votre prochain séjour avec SenReservations."
        />
        <link rel="canonical" href={`${baseUrl}/blog`} />
        <link rel="alternate" href={`${baseUrl}/blog`} hrefLang="fr-SN" />
        <script type="application/ld+json">
          {JSON.stringify(blogItemSchema)}
        </script>
      </Helmet>
      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-20">
        <h2 className="text-4xl font-bold text-gray-800 mb-10">
          Explorez Nos Conseils de Voyage et Inspirations
        </h2>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogItems.map((blogItem) => (
            <div
              key={blogItem.id}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <img
                src={blogItem.imageUrl}
                alt={blogItem.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-4">
                <h3 className="text-2xl font-semibold text-gray-800 mb-2">
                  {blogItem.title}
                </h3>
                <p className="text-gray-600">{blogItem.excerpt}</p>
                <Link
                  to={`/blog/${blogItem.slug}`}
                  className="text-blue-600 hover:underline mt-4 block"
                >
                  Lire la suite
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

// Wrap the BlogContent with BlogProvider
const Blog: React.FC = () => (
  <BlogProvider>
    <BlogContent />
  </BlogProvider>
);

export default Blog;
