export function capitalizeDestinationName(
  destination: string | undefined
): string {
  if (!destination) {
    return ""; // Return empty string if destination is undefined or empty
  }

  return destination
    .trim() // Remove any leading/trailing spaces
    .split(" ") // Split the string into words
    .map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize each word
    )
    .join(" "); // Join the words back into a single string
}
