import React from "react";
import { Link } from "react-router-dom";
import { useDestinations } from "../dataProviders/DestinationProvider";
import ReactGA from "react-ga4";

const Destinations: React.FC = () => {
  const { senegalDestinations, internationalDestinations } = useDestinations();

  const gaSendDestinationLinkClickEvent = (
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    const url = e.currentTarget.getAttribute("href"); // Get the URL
    if (url) {
      const destination = url.split("/").pop(); // Extract the destination from the URL
      ReactGA.event({
        category: "Destination Link",
        action: "Click",
        label: destination,
      });
    }
  };

  return (
    <>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
        <h2 className="text-3xl font-bold text-gray-800">
          Destinations Populaires
        </h2>
        <p className="text-lg text-gray-600 mt-2">
          Découvrez les meilleures destinations pour vos prochaines vacances.
        </p>
      </div>

      {/* Top Destinations in Senegal */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        <h3 className="text-2xl font-bold text-gray-800">
          Destinations au Sénégal
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
          {senegalDestinations.map((destination, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <Link
                to={`/sejours/${encodeURIComponent(
                  destination.name.toLowerCase()
                )}`}
                onClick={gaSendDestinationLinkClickEvent}
                state={{ destination: destination.locationName }}
                key={destination.name}
                className="block shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
              >
                <img
                  src={destination.imageUrl}
                  alt={destination.locationName}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <h4 className="text-lg font-semibold text-gray-800">
                    {destination.name}
                  </h4>
                  <p className="text-gray-600">{destination.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>

      {/* Top International Destinations */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mt-10">
        <h3 className="text-2xl font-bold text-gray-800">
          Destinations Internationales
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6 mt-4">
          {internationalDestinations.map((destination, index) => (
            <div
              key={index}
              className="bg-white shadow-md rounded-lg overflow-hidden"
            >
              <Link
                to={`/sejours/${encodeURIComponent(
                  destination.name.toLowerCase()
                )}`}
                onClick={gaSendDestinationLinkClickEvent}
                state={{ destination: destination.locationName }}
                key={destination.name}
                className="block shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105"
              >
                <img
                  src={destination.imageUrl}
                  alt={destination.locationName}
                  className="w-full h-40 object-cover"
                />
                <div className="p-4">
                  <h4 className="text-lg font-semibold text-gray-800">
                    {destination.locationName}
                  </h4>
                  <p className="text-gray-600">{destination.description}</p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Destinations;
