const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

// Define the structure of destination data
export interface Destination {
  name: string;
  locationName: string;
  description: string;
  imageUrl: string;
}

export const senegalDestinations: Destination[] = [
  {
    name: "Dakar",
    locationName: "Dakar, Sénégal",
    description: "La capitale vibrante et culturelle du Sénégal.",
    imageUrl: `${baseImageUrl}/destinations/dakar.jpg`,
  },
  {
    name: "Saly",
    locationName: "Saly Portudal, Sénégal",
    description: "Station balnéaire prisée avec de magnifiques plages.",
    imageUrl: `${baseImageUrl}/destinations/saly.jpg`,
  },
  {
    name: "Saint-Louis",
    locationName: "Saint-Louis, Sénégal",
    description: "Ville historique avec une architecture coloniale.",
    imageUrl: `${baseImageUrl}/destinations/saint-louis.jpg`,
  },
  {
    name: "Cap Skirring",
    locationName: "Cap Skirring, Casamance, Sénégal",
    description: "Un paradis côtier au sud du Sénégal.",
    imageUrl: `${baseImageUrl}/destinations/cap-skirring.jpg`,
  },
];

export const internationalDestinations: Destination[] = [
  {
    name: "Paris",
    locationName: "Paris, France",
    description: "Destination favorite des voyageurs sénégalais.",
    imageUrl: `${baseImageUrl}/destinations/paris.jpg`,
  },
  {
    name: "Dubai",
    locationName: "Dubai, Émirats Arabes Unis",
    description: "Connue pour ses merveilles modernes et luxueuses.",
    imageUrl: `${baseImageUrl}/destinations/dubai.jpg`,
  },
  {
    name: "Marrakech",
    locationName: "Marrakech, Maroc",
    description: "Ville riche en culture et en histoire.",
    imageUrl: `${baseImageUrl}/destinations/marrakech.jpg`,
  },
  {
    name: "Istanbul",
    locationName: "Istanbul, Turquie",
    description: "Un carrefour entre l'Orient et l'Occident.",
    imageUrl: `${baseImageUrl}/destinations/istanbul.jpg`,
  },
];
