import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import PartnerLink from "../components/PartnerLink";
import { BlogProvider, useBlogItems } from "../dataProviders/BlogProvider";
import { BlogItem } from "../sharedData/blog";

const BlogPostContent: React.FC = () => {
  const { slug } = useParams<{ slug?: string }>();
  const { blogItems } = useBlogItems();
  const blogData: Record<
    string,
    {
      content: JSX.Element;
    }
  > = {
    "destinations-senegal-2025": {
      content: (
        <>
          <p>
            Le Sénégal est une destination de plus en plus prisée pour ses
            plages paradisiaques, sa riche culture et ses sites historiques.
            Voici notre sélection des meilleures destinations pour vos vacances
            en 2024.
          </p>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            1. Dakar - La Capitale Vibrante
          </h2>
          <p>
            Dakar, la capitale dynamique du Sénégal, est un mélange de modernité
            et de tradition. Visitez l’Île de Gorée pour un aperçu historique,
            détendez-vous sur les plages de Ngor et explorez le Marché Kermel
            pour des souvenirs artisanaux.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>
              <strong>Monument de la Renaissance africaine</strong> - Une statue
              colossale offrant une vue panoramique.
            </li>
            <li>
              <strong>Village des Arts</strong> - Un centre créatif où vous
              pouvez rencontrer des artistes locaux.
            </li>
          </ul>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            2. Saly - La Station Balnéaire
          </h2>
          <p>
            Connue pour ses plages, Saly est l’endroit idéal pour les amateurs
            de soleil. Profitez des sports nautiques, des restaurants en bord de
            mer et de l’ambiance relaxante.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>
              <strong>Golf de Saly</strong> - Un superbe parcours de golf pour
              les amateurs.
            </li>
            <li>
              <strong>Excursions en bateau</strong> - Découvrez la côte depuis
              l’eau avec des promenades en bateau.
            </li>
          </ul>
        </>
      ),
    },
    "conseils-voyage-senegal": {
      content: (
        <>
          <p>
            Pour profiter pleinement de votre séjour au Sénégal, suivez ces
            conseils pratiques afin de rendre votre voyage plus agréable et sans
            tracas.
          </p>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            1. Choisissez la Bonne Saison
          </h2>
          <p>
            Le climat sénégalais est tropical avec une saison des pluies de juin
            à octobre. Pour des vacances ensoleillées, préférez la période de
            novembre à mai.
          </p>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            2. Préparez Votre Santé
          </h2>
          <p>
            Assurez-vous de mettre à jour vos vaccins et emportez de
            l’antimoustique, surtout si vous prévoyez de visiter les zones
            rurales.
          </p>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            3. Respectez la Culture Locale
          </h2>
          <p>
            Le Sénégal est un pays accueillant avec une riche culture. Il est
            conseillé de s’habiller modestement, surtout dans les zones rurales.
          </p>
        </>
      ),
    },
    "meilleurs-hebergements-senegal": {
      content: (
        <>
          <p>
            Que vous voyagiez avec un budget limité ou recherchiez le luxe, le
            Sénégal offre une variété d'options d'hébergement adaptées à tous
            les budgets.
          </p>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            1. Hôtels de Luxe
          </h2>
          <p>
            Pour un séjour haut de gamme, explorez les hôtels de luxe à Dakar et
            Saly, qui offrent des commodités de qualité et une vue sur l’océan.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>
              <strong>Radisson Blu, Dakar</strong> - Un hôtel de luxe avec
              piscine et vue imprenable sur l'Atlantique.
            </li>
            <li>
              <strong>Royal Horizon Baobab, Saly</strong> - Un complexe avec
              accès privé à la plage.
            </li>
          </ul>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            2. Maisons d'Hôtes
          </h2>
          <p>
            Pour une expérience locale, séjournez dans une maison d'hôtes où
            l'accueil est chaleureux et les prix abordables.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>
              <strong>La Villa Saint-Louis</strong> - Une maison d'hôtes
              charmante et confortable.
            </li>
            <li>
              <strong>Chez Salim, Lac Rose</strong> - Une maison d'hôtes avec
              une atmosphère traditionnelle et un service amical.
            </li>
          </ul>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            3. Appartements
          </h2>
          <p>
            Pour plus d'indépendance et de flexibilité, optez pour un
            appartement, idéal pour les familles ou les séjours prolongés.
            Profitez d’un espace personnel avec cuisine et toutes les
            commodités.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>
              <strong>Appartement Ngor, Dakar</strong> - Un appartement moderne
              proche de la plage de Ngor, parfait pour un séjour tranquille.
            </li>
            <li>
              <strong>Appartements Résidence les Baobabs, Saly</strong> - Des
              appartements spacieux avec piscine commune, situés au cœur de
              Saly.
            </li>
          </ul>

          <h2 className="text-3xl font-semibold text-gray-800 mt-8 mb-4">
            4. Options Budget
          </h2>
          <p>
            Pour les voyageurs à petit budget, les auberges et petits hôtels
            dans les grandes villes offrent des chambres simples à prix
            abordable.
          </p>
          <ul className="list-disc list-inside mt-2 text-gray-600">
            <li>
              <strong>Auberge Keur Diame, Dakar</strong> - Une auberge abordable
              proche de la plage.
            </li>
            <li>
              <strong>Chez Marie, Ziguinchor</strong> - Un hôtel confortable
              dans la région de Casamance.
            </li>
          </ul>
        </>
      ),
    },
  };

  const emptyBlogItem: BlogItem = {
    id: 0,
    date: "",
    title: "",
    shortTitle: "",
    excerpt: "",
    imageUrl: "",
    slug: "",
  };

  // Function to find a blog item by slug
  function findBlogItemBySlug(slug: string | undefined): BlogItem {
    return blogItems.find((item) => item.slug === slug) || emptyBlogItem;
  }

  // Check that `slug` is defined and get the corresponding blog post data
  const post = slug ? blogData[slug] : null;
  const blogItem = findBlogItemBySlug(slug);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const encodedSlug = slug ? encodeURIComponent(slug.toLowerCase()) : "";

  if (!post) return <p>Post not found</p>;

  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>
          {post
            ? `${blogItem.shortTitle} | SenReservations`
            : "Blog | SenReservations"}
        </title>
        <meta
          name="description"
          content={
            post
              ? blogItem.title
              : "Lisez nos articles de blog pour des conseils de voyage et des inspirations."
          }
        />
        <link rel="canonical" href={`${baseUrl}/blog/${encodedSlug}`} />
        <link
          rel="alternate"
          href={`${baseUrl}/blog/${encodedSlug}`}
          hrefLang="fr-SN"
        />
      </Helmet>
      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-20">
        <h1 className="text-4xl font-bold text-gray-800 mb-6">
          {blogItem.title}
        </h1>
        <p className="text-gray-600 mb-4">
          <em>Publié le {blogItem.date}</em>
        </p>
        <img
          src={blogItem.imageUrl}
          alt={blogItem.title}
          className="w-full h-96 object-cover rounded-md mb-8"
        />
        <article className="text-gray-700 leading-relaxed">
          {post.content}
        </article>
        <PartnerLink />
      </div>
    </>
  );
};

// Wrap the BlogPostContent with BlogProvider
const BlogPost: React.FC = () => (
  <BlogProvider>
    <BlogPostContent />
  </BlogProvider>
);

export default BlogPost;
