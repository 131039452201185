import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  FaBed,
  FaCalendarAlt,
  FaUserFriends,
  FaChild,
  FaDoorClosed,
  FaSearch,
  FaTimes,
} from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

interface StaySearchBarProps {
  onSearch?: (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => void;
  hideHeader?: boolean;
  initialValues?: {
    destination?: string;
    checkIn?: string;
    checkOut?: string;
    adults?: number;
    children?: number;
    rooms?: number;
    childAges?: number[];
  };
  isSearchResultPage?: boolean; // New prop to determine if it's used on the SearchResult page
}

const StaySearchBar: React.FC<StaySearchBarProps> = ({
  onSearch,
  hideHeader = false,
  initialValues = {},
  isSearchResultPage = false, // Default to false
}) => {
  const initialValuesAsString = JSON.stringify(initialValues);
  const [destination, setDestination] = useState(
    initialValues.destination || ""
  );
  const [checkInDate, setCheckInDate] = useState<Date | null>(
    initialValues.checkIn ? new Date(initialValues.checkIn) : null
  );
  const [checkOutDate, setCheckOutDate] = useState<Date | null>(
    initialValues.checkOut ? new Date(initialValues.checkOut) : null
  );
  const [adults, setAdults] = useState(initialValues.adults || 2);
  const [children, setChildren] = useState(initialValues.children || 0);
  const [rooms, setRooms] = useState(initialValues.rooms || 1);
  const [childAges, setChildAges] = useState<number[]>(
    initialValues.childAges || []
  );
  const [guestsDropdownOpen, setGuestsDropdownOpen] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [previousSearchParams, setPreviousSearchParams] = useState(
    initialValuesAsString
  );
  const [collapsed, setCollapsed] = useState(true); // Collapsing state for mobile view on SearchResult page
  const [honeypot, setHoneypot] = useState(""); // Honeypot field for bots

  const dropdownRef = useRef<HTMLDivElement>(null);
  const checkInRef = useRef<DatePicker | null>(null);
  const checkOutRef = useRef<DatePicker | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  const todaysDate = new Date();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setGuestsDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDestinationChange = (value: string) => {
    setDestination(value);
    if (value.trim() !== "") {
      setErrors((prevErrors) => ({ ...prevErrors, destination: "" }));
    }
  };

  // Check-in Date Input Handler
  const handleCheckInDateChange = (date: Date | null) => {
    setCheckInDate(date);
    if (date) {
      setErrors((prevErrors) => ({ ...prevErrors, checkInDate: "" }));
    }
    // Reset check-out date if it is before the new check-in date
    if (checkOutDate && date && checkOutDate <= date) {
      setCheckOutDate(null);
    }
  };

  // Check-out Date Input Handler
  const handleCheckOutDateChange = (date: Date | null) => {
    setCheckOutDate(date);
    if (date && checkInDate && date > checkInDate) {
      setErrors((prevErrors) => ({ ...prevErrors, checkOutDate: "" }));
    }
  };

  // Handle changes in children count and dynamically create child age inputs
  const handleChildrenChange = (count: number) => {
    setChildren(count);
    const newAges = new Array(count).fill(0);
    setChildAges(newAges);
  };

  // Handle change of child age for a specific index
  const handleChildAgeChange = (index: number, age: number) => {
    const newAges = [...childAges];
    newAges[index] = age;
    setChildAges(newAges);
  };

  const formatDate = (date: Date | null) =>
    date ? date.toISOString().split("T")[0] : "";

  const validate = () => {
    const newErrors: { [key: string]: string } = {};
    if (!destination.trim())
      newErrors.destination = "La destination est requise.";
    if (!checkInDate) newErrors.checkInDate = "La date d'arrivée est requise.";
    if (!checkOutDate)
      newErrors.checkOutDate = "La date de départ est requise.";
    else if (checkInDate && checkOutDate <= checkInDate) {
      newErrors.checkOutDate =
        "La date de départ doit être après la date d'arrivée.";
    }
    if (adults < 1) newErrors.guests = "Il doit y avoir au moins un adulte.";
    if (rooms < 1) newErrors.rooms = "Il doit y avoir au moins une chambre.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const gaSendSearchEvent = () => {
    ReactGA.event({
      category: "Search",
      action: "Submit Search Form",
      label: destination,
      value: 1,
      nonInteraction: false,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (honeypot) {
      return; // Skip search if honeypot field is filled
    }

    if (!validate()) return;

    const searchParams = {
      destination,
      checkIn: formatDate(checkInDate),
      checkOut: formatDate(checkOutDate),
      adults,
      children,
      rooms,
      childAges,
    };

    const searchString = JSON.stringify(searchParams);

    if (searchString === previousSearchParams) {
      alert(
        "Veuillez modifier les critères de recherche avant de rechercher à nouveau."
      );
      return;
    }

    gaSendSearchEvent();

    setPreviousSearchParams(searchString);

    if (location.pathname.startsWith("/sejours") && onSearch) {
      onSearch(searchParams);
    } else {
      navigate(`/sejours/${destination}`, { state: searchParams });
    }
    // Collapse the search bar after submitting
    // This would only take effect in mobile view, ignored otherwise
    setCollapsed(true);
  };

  return (
    <section className="p-8 bg-gray-100">
      {!hideHeader && (
        <div className="max-w-7xl mx-auto text-center mb-8">
          <h1 className="text-2xl md:text-4xl font-bold text-gray-800 mb-4">
            Trouvez le séjour parfait
          </h1>
          <p className="text-lg text-gray-600">
            <span className="block md:hidden">
              Explorez les meilleures offres.
            </span>
            <span className="hidden md:block">
              Explorez les meilleures offres d'hébergement au Sénégal et dans
              des destinations internationales populaires.
            </span>
          </p>
        </div>
      )}

      <div className="max-w-7xl mx-auto">
        {isSearchResultPage ? (
          <>
            {/* Collapsed Search Controls for Mobile View */}
            <div
              className="md:hidden flex items-center justify-between w-full bg-gray-100 py-2 px-4 rounded-lg border border-gray-300 mt-4"
              onClick={() => setCollapsed(!collapsed)}
            >
              <div className="flex items-center space-x-2 text-gray-700">
                <FaBed className="text-gray-500" />
                <span className="text-sm truncate">
                  {destination || "Destination"}
                </span>

                <FaCalendarAlt className="text-gray-500 ml-3" />
                <span className="text-sm">
                  {checkInDate
                    ? checkInDate.toLocaleDateString("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                      })
                    : "Arrivée"}{" "}
                  /{" "}
                  {checkOutDate
                    ? checkOutDate.toLocaleDateString("fr-FR", {
                        day: "2-digit",
                        month: "2-digit",
                      })
                    : "Départ"}
                </span>

                <FaUserFriends className="text-gray-500 ml-3" />
                <span className="text-sm">{adults + children}</span>

                <FaDoorClosed className="text-gray-500 ml-3" />
                <span className="text-sm">{rooms}</span>
              </div>
              <FaSearch className="text-blue-600" />
            </div>

            {/* Expanded Search Form for Mobile and Desktop Views */}
            {!collapsed && (
              <form
                onSubmit={handleSubmit}
                className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 space-y-4 md:space-y-0 md:space-x-4"
              >
                {/* Search Form Elements (unchanged) */}
                {renderSearchFormElements()}
              </form>
            )}

            {/* Always Display Search Form on Desktop */}
            <div className="hidden md:block">
              <form
                onSubmit={handleSubmit}
                className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 space-y-4 md:space-y-0 md:space-x-4"
              >
                {/* Search Form Elements (unchanged) */}
                {renderSearchFormElements()}
              </form>
            </div>
          </>
        ) : (
          <form
            onSubmit={handleSubmit}
            className="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between bg-white shadow-lg rounded-lg p-6 space-y-4 md:space-y-0 md:space-x-4"
          >
            {/* Search Form Elements (unchanged) */}
            {renderSearchFormElements()}
          </form>
        )}
      </div>
    </section>
  );

  // Function to render search form elements to avoid duplication
  function renderSearchFormElements() {
    return (
      <>
        {/* Honeypot input field - hidden from users */}
        <input
          type="text"
          value={honeypot}
          onChange={(e) => setHoneypot(e.target.value)}
          style={{ display: "none" }}
          tabIndex={-1} // Make the field unreachable for users
        />
        {/* Destination Input */}
        <div className="relative flex flex-col w-full md:w-1/4">
          <div className="flex items-center border border-gray-300 rounded-md p-3">
            <FaBed className="text-gray-500 mr-3" />
            <input
              type="text"
              value={destination}
              onChange={(e) => handleDestinationChange(e.target.value)}
              placeholder="Destination"
              className="w-full outline-none text-gray-700"
            />
          </div>
          <div className="absolute -bottom-5 left-0 w-full h-5">
            {errors.destination && (
              <p className="text-red-500 text-sm">{errors.destination}</p>
            )}
          </div>
        </div>

        {/* Check-in Date Input */}
        <div className="relative flex flex-col w-full md:w-1/4">
          <div className="flex items-center border border-gray-300 rounded-md p-3">
            <FaCalendarAlt
              className="text-gray-500 mr-3 cursor-pointer"
              onClick={() => checkInRef.current?.setFocus()}
            />
            <DatePicker
              selected={checkInDate}
              onChange={handleCheckInDateChange}
              placeholderText="Date d'arrivée"
              className="w-full outline-none text-gray-700"
              ref={checkInRef}
              minDate={todaysDate} // Check-in date must be at least today
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="absolute -bottom-5 left-0 w-full h-5">
            {errors.checkInDate && (
              <p className="text-red-500 text-sm">{errors.checkInDate}</p>
            )}
          </div>
        </div>

        {/* Check-out Date Input */}
        <div className="relative flex flex-col w-full md:w-1/4">
          <div className="flex items-center border border-gray-300 rounded-md p-3">
            <FaCalendarAlt
              className="text-gray-500 mr-3 cursor-pointer"
              onClick={() => checkOutRef.current?.setFocus()}
            />
            <DatePicker
              selected={checkOutDate}
              onChange={handleCheckOutDateChange}
              placeholderText="Date de départ"
              className="w-full outline-none text-gray-700"
              ref={checkOutRef}
              minDate={
                checkInDate
                  ? new Date(checkInDate.getTime() + 24 * 60 * 60 * 1000)
                  : todaysDate
              } // Check-out date is one day after check-in
              dateFormat="dd/MM/yyyy"
            />
          </div>
          <div className="absolute -bottom-5 left-0 w-full h-5">
            {errors.checkOutDate && (
              <p className="text-red-500 text-sm">{errors.checkOutDate}</p>
            )}
          </div>
        </div>

        {/* Guests Dropdown */}
        <div className="relative w-full md:w-1/4" ref={dropdownRef}>
          <div
            className="flex items-center border border-gray-300 rounded-md p-3 cursor-pointer space-x-2"
            onClick={() => setGuestsDropdownOpen(!guestsDropdownOpen)}
          >
            <span className="text-gray-700 flex items-center space-x-2">
              <FaUserFriends /> <span>{adults}</span>
              <FaChild /> <span>{children}</span>
              <FaDoorClosed /> <span>{rooms}</span>
            </span>
          </div>
          {errors.guests && (
            <p className="text-red-500 text-sm mt-1">{errors.guests}</p>
          )}
          {guestsDropdownOpen && (
            <div className="absolute bg-white border border-gray-300 rounded-lg mt-2 shadow-lg w-full z-10 p-4">
              <div className="flex justify-between items-center mb-2">
                <label className="text-gray-700">Adultes</label>
                <input
                  type="number"
                  min="1"
                  value={adults}
                  onChange={(e) => setAdults(Number(e.target.value))}
                  className="w-16 p-1 border rounded-md text-center"
                />
              </div>
              <div className="flex justify-between items-center mb-2">
                <label className="text-gray-700">Enfants</label>
                <input
                  type="number"
                  min="0"
                  value={children}
                  onChange={(e) => handleChildrenChange(Number(e.target.value))}
                  className="w-16 p-1 border rounded-md text-center"
                />
              </div>
              <div className="flex justify-between items-center mb-2">
                <label className="text-gray-700">Chambres</label>
                <input
                  type="number"
                  min="1"
                  value={rooms}
                  onChange={(e) => setRooms(Number(e.target.value))}
                  className="w-16 p-1 border rounded-md text-center"
                />
              </div>

              {/* Child Age Inputs */}
              {children > 0 &&
                childAges.map((age, index) => (
                  <div
                    key={index}
                    className="flex justify-between items-center mb-2"
                  >
                    <label className="text-gray-700">
                      Âge de l'enfant {index + 1}
                    </label>
                    <input
                      type="number"
                      min="0"
                      value={age}
                      onChange={(e) =>
                        handleChildAgeChange(index, Number(e.target.value))
                      }
                      className="w-16 p-1 border rounded-md text-center"
                    />
                  </div>
                ))}
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setGuestsDropdownOpen(false)}
                  className="mt-2 bg-blue-600 text-white font-bold py-1 px-4 rounded-md hover:bg-blue-700"
                >
                  Terminé
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="bg-blue-600 text-white font-bold py-2 px-6 rounded-md hover:bg-blue-700 transition-all w-full md:w-auto"
        >
          Rechercher
        </button>
      </>
    );
  }
};

export default StaySearchBar;
