import React, { createContext, useContext } from "react";
import { blogItems, BlogItem } from "../sharedData/blog";

interface BlogItemContextType {
  blogItems: BlogItem[];
}

// Create a context with a default value
const BlogItemContext = createContext<BlogItemContextType | undefined>(
  undefined
);

// Provider component
export const BlogProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <BlogItemContext.Provider value={{ blogItems }}>
      {children}
    </BlogItemContext.Provider>
  );
};

// Custom hook for easy access to the context
export const useBlogItems = () => {
  const context = useContext(BlogItemContext);
  if (!context) {
    throw new Error("useBlogItems must be used within a BlogProvider");
  }
  return context;
};
