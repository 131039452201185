import React from "react";
import PartnerLink from "./PartnerLink";
import { capitalizeDestinationName } from "../utils/stringUtils";

const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;
// Define SEO content for each top destination, can be move into a data store later
// Top Destination
type DestinationKey =
  | "dakar"
  | "paris"
  | "saly"
  | "saint-louis"
  | "cap skirring"
  | "dubai"
  | "marrakech"
  | "istanbul";
// Define SEO content for each destination with DestinationKey as the type for keys
const destinationContent: Record<
  DestinationKey,
  { description: JSX.Element; imageUrl: string }
> = {
  dakar: {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Découvrez Dakar, la capitale vibrante
        </h2>
        <p>
          Dakar est un mélange captivant d'histoire, de culture et de modernité.
          Explorez l'<strong>île de Gorée</strong>, un site classé au patrimoine
          mondial de l'UNESCO, et découvrez son histoire poignante à la Maison
          des Esclaves. Plongez dans la culture dynamique de la ville avec des
          visites au <em>Museum of Black Civilizations</em>, qui célèbre la
          richesse des cultures africaines.
        </p>
        <p className="mt-2">
          La scène gastronomique de Dakar est en plein essor, avec des
          restaurants qui marient saveurs locales et influences internationales.
          Ne manquez pas de visiter les marchés animés comme le{" "}
          <strong>marché de Soumbédioune</strong> pour des souvenirs artisanaux.
          Pour une journée de détente, profitez des plages de <em>Yoff</em> ou
          de <strong>N'Gor</strong>, parfaites pour le surf et les bains de
          soleil.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/dakar.jpg`,
  },
  saly: {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Saly, votre paradis balnéaire
        </h2>
        <p>
          Saly est une station balnéaire de premier choix, réputée pour ses
          plages magnifiques et ses stations luxueuses. Profitez des sports
          nautiques comme la plongée, le jet-ski, ou détendez-vous dans un cadre
          idyllique. Découvrez la <strong>Réserve de Bandia</strong>, un parc
          naturel abritant des girafes, rhinocéros et autres animaux
          emblématiques de la faune africaine.
        </p>
        <p className="mt-2">
          Pour une immersion culturelle, explorez les villages environnants et
          engagez-vous avec les artisans locaux pour découvrir leur
          savoir-faire. Saly offre une ambiance parfaite pour une escapade
          relaxante tout en offrant des expériences authentiques de la culture
          sénégalaise.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/saly.jpg`,
  },
  "saint-louis": {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Saint-Louis, un joyau historique
        </h2>
        <p>
          Saint-Louis, l'ancienne capitale coloniale, est célèbre pour son
          architecture française et son riche patrimoine culturel. Traversez le{" "}
          <strong>Pont Faidherbe</strong>, un monument emblématique qui relie
          l'île de Saint-Louis au continent. Promenez-vous dans les rues
          pittoresques de la ville, qui abritent des bâtiments historiques aux
          couleurs pastel.
        </p>
        <p className="mt-2">
          Participez au célèbre <em>Festival de Jazz de Saint-Louis</em> pour
          une expérience musicale inoubliable. Les amateurs de nature
          apprécieront le{" "}
          <strong>Parc national de la Langue de Barbarie</strong>, un site idéal
          pour observer des oiseaux rares et profiter des paysages naturels
          époustouflants.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/saint-louis.jpg`,
  },
  "cap skirring": {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Cap Skirring, une destination idyllique
        </h2>
        <p>
          Cap Skirring est célèbre pour ses plages de sable blanc et son
          atmosphère détendue. Idéale pour les amateurs de sports nautiques
          comme le surf et la pêche, cette destination offre également des
          possibilités de randonnées à travers la forêt tropicale.
        </p>
        <p className="mt-2">
          Immergez-vous dans la culture locale en participant aux activités
          communautaires Diola, où vous découvrirez les traditions, la cuisine
          et la musique de la région. Cap Skirring est un endroit parfait pour
          se détendre tout en explorant la richesse de la culture sénégalaise.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/cap-skirring.jpg`,
  },
  paris: {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Paris, la ville de l'amour et de la culture
        </h2>
        <p>
          Paris reste une destination de rêve pour les voyageurs. Visitez des
          sites emblématiques comme la <strong>Tour Eiffel</strong>, le
          <em>Musée du Louvre</em> et la <strong>Cathédrale Notre-Dame</strong>.
          Les amateurs de shopping trouveront leur bonheur sur les{" "}
          <em>Champs-Élysées</em>, tandis que les gourmets pourront savourer une
          cuisine française raffinée dans les bistrots locaux.
        </p>
        <p className="mt-2">
          Découvrez les quartiers branchés comme le Marais, réputé pour ses
          galeries d'art et ses cafés animés, ou promenez-vous le long de la
          Seine pour une vue romantique de la ville. Paris vous invite à
          explorer son riche patrimoine tout en vivant des expériences modernes
          inoubliables.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/paris.jpg`,
  },
  dubai: {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Dubaï, le luxe et l'innovation
        </h2>
        <p>
          Dubaï est une ville de superlatifs, connue pour ses gratte-ciel
          imposants comme le <strong>Burj Khalifa</strong>, ses centres
          commerciaux extravagants, et ses expériences de luxe. Visitez le{" "}
          <em>Dubai Mall</em>, où vous trouverez des attractions comme une
          patinoire et un immense aquarium.
        </p>
        <p className="mt-2">
          Faites un tour dans le désert pour une aventure palpitante en 4x4, ou
          détendez-vous sur les plages immaculées de Jumeirah. La scène
          gastronomique de Dubaï continue de s'épanouir avec des restaurants
          proposant des plats du monde entier, souvent avec des concepts uniques
          qui marient modernité et traditions.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/dubai.jpg`,
  },
  marrakech: {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Marrakech, un mélange de traditions et de modernité
        </h2>
        <p>
          Marrakech enchante les visiteurs avec sa médina animée, ses
          <strong> souks</strong> débordant d'épices et d'artisanat, et ses
          palais somptueux. Le <em>Jardin Majorelle</em>, un havre de paix
          rempli de plantes exotiques, est un incontournable.
        </p>
        <p className="mt-2">
          Découvrez les <strong>hammams</strong> traditionnels pour une
          expérience de bien-être, et terminez la journée en vous délectant des
          saveurs marocaines dans un riad. Le soir, la place Jemaa el-Fna
          s'anime avec des conteurs, des musiciens et des stands de nourriture
          qui éveillent tous les sens.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/marrakech.jpg`,
  },
  istanbul: {
    description: (
      <div>
        <h2 className="text-xl font-bold mb-2">
          Istanbul, où l'Orient rencontre l'Occident
        </h2>
        <p>
          Istanbul est une ville unique, à cheval entre l'Europe et l'Asie.
          Visitez la <strong>Mosquée Bleue</strong>, admirez la grandeur du{" "}
          <em>Palais de Topkapi</em>, et explorez le{" "}
          <strong>Grand Bazar</strong>, l'un des plus grands marchés couverts au
          monde.
        </p>
        <p className="mt-2">
          Faites une croisière sur le Bosphore pour des vues imprenables, et
          découvrez la cuisine turque authentique dans des restaurants
          traditionnels. Avec son architecture magnifique et sa culture riche,
          Istanbul est une destination qui fascine et inspire.
        </p>
      </div>
    ),
    imageUrl: `${baseImageUrl}/destinations/istanbul.jpg`,
  },
};

const DestinationOverview: React.FC<{
  destination?: string;
  searchInitiated: boolean;
}> = ({ destination, searchInitiated }) => {
  const destinationKey = destination as DestinationKey;
  return (
    <>
      {/* Initial SEO Content if Search not Initiated */}
      {!searchInitiated && destinationKey in destinationContent ? (
        <>
          <div className="mb-8 flex flex-col md:flex-row items-start md:space-x-6">
            <img
              src={destinationContent[destinationKey].imageUrl}
              alt={`Séjours à ${capitalizeDestinationName(destination)}`}
              className="w-full md:w-64 h-64 object-cover rounded-lg shadow-md"
            />
            <div className="text-left mt-4 md:mt-0">
              <h1 className="text-2xl font-bold text-gray-800 mb-4">
                Séjours à {capitalizeDestinationName(destination)}
              </h1>
              <div className="text-gray-700">
                {destinationContent[destinationKey].description}
              </div>
            </div>
          </div>
          <div className="mb-10">
            <PartnerLink destination={destination} />
          </div>
        </>
      ) : null}
    </>
  );
};

export default DestinationOverview;
