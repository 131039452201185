import React from "react";
import { Helmet } from "react-helmet-async";

const AboutPage: React.FC = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>À propos | SenReservations</title>
        <meta
          name="description"
          content="SenReservations facilite vos recherches et réservations de séjours, de vols, et plus encore. Découvrez notre mission et notre engagement envers une meilleure expérience de voyage."
        />
        <link rel="canonical" href={`${baseUrl}/a-propos`} />
        <link rel="alternate" href={`${baseUrl}/a-propos`} hrefLang="fr-SN" />
      </Helmet>
      {/* Main content */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-10 pt-20">
        <h1 className="text-4xl font-extrabold text-gray-800 mb-8">À propos</h1>

        <p className="text-gray-600 mb-6 text-lg">
          Bienvenue chez <strong>SenReservations</strong>, votre partenaire de
          confiance pour la réservation de séjours et de voyages au Sénégal, en
          Afrique de l'Ouest, et dans le monde entier. Notre mission est de
          fournir une plateforme conviviale et fiable qui simplifie la
          recherche, la comparaison et la réservation d'hôtels, d'hébergements
          et de services de voyage adaptés aux besoins uniques de chaque
          voyageur.
        </p>

        <h2 className="text-2xl font-bold text-gray-800 mt-10 mb-4">
          Pourquoi nous choisir ?
        </h2>
        <ul className="list-disc list-inside text-gray-600 mb-6 text-lg">
          <li>
            <strong>Une large sélection d'hébergements :</strong> Nous offrons
            un vaste éventail d'options d'hébergement, allant des hôtels de luxe
            aux auberges locales, pour répondre à tous les goûts et budgets.
          </li>
          <li>
            <strong>Interface conviviale :</strong> Notre plateforme est conçue
            pour être facile à utiliser, avec des fonctionnalités intuitives et
            une navigation fluide.
          </li>
          <li>
            <strong>Des partenaires de confiance :</strong> Nous collaborons
            avec des prestataires de services de premier plan, garantissant des
            séjours de qualité et des expériences mémorables.
          </li>
        </ul>

        <h2 className="text-2xl font-bold text-gray-800 mt-10 mb-4">
          Notre vision
        </h2>
        <p className="text-gray-600 mb-6 text-lg">
          Chez SenReservations, nous croyons que le voyage doit être une
          expérience enrichissante et accessible à tous. Notre vision est de
          devenir la référence incontournable pour la réservation de séjours au
          Sénégal et en Afrique de l'Ouest, en offrant des solutions pratiques
          et sécurisées qui mettent en valeur la richesse culturelle et
          naturelle des destinations.
        </p>

        <h2 className="text-2xl font-bold text-gray-800 mt-10 mb-4">
          Rejoignez-nous
        </h2>
        <p className="text-gray-600 mb-6 text-lg">
          Nous recherchons continuellement des partenaires stratégiques pour
          élargir notre réseau et offrir encore plus de possibilités aux
          voyageurs. Que vous soyez un hôtel, un fournisseur de services de
          voyage ou un investisseur souhaitant contribuer à l'essor du tourisme
          à l'échelle locale ou internationale, nous serions ravis de collaborer
          avec vous.
        </p>
        <p className="text-gray-600 mb-6 text-lg">
          Contactez-nous dès aujourd'hui pour discuter des opportunités de
          partenariat et découvrir comment nous pouvons collaborer pour offrir
          des expériences de voyage inoubliables.
        </p>
      </div>
    </>
  );
};

export default AboutPage;
