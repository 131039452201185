import React, { useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import axios, { AxiosResponse } from "axios";
import StaySearchBar from "../components/StaySearchBar";
import DestinationOverview from "../components/DestinationOverview";
import Pagination from "../components/Pagination";
import { FaChevronRight } from "react-icons/fa";
import { capitalizeDestinationName } from "../utils/stringUtils";

// Interface for search result items
interface SearchResultItem {
  id: string;
  title: string;
  shordesc: string;
  location: string;
  price: number;
  url: string;
  imageUrl: string;
  source: string;
  starRating: number;
  distFrmCityCtr: string;
  freeBreakfast: boolean;
  freeCancellation: boolean;
}

const SearchResult: React.FC = () => {
  const { destination } = useParams<{ destination: string }>();
  const location = useLocation();
  const initialSearchParams = location.state || {}; // Retrieve initial search parameters from state
  const [results, setResults] = useState<SearchResultItem[]>([]);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [loadingCache, setLoadingCache] = useState(false);
  const [error, setError] = useState("");
  const [searchInitiated, setSearchInitiated] = useState(false);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [totalPages, setTotalPages] = useState(1);

  const baseImageUrl = process.env.REACT_APP_IMAGE_BASE_URL;

  const ProviderLookup: { [key: string]: string } = {
    "Booking.com": `${baseImageUrl}/logos/booking-logo.jpg`,
    airbnb: `${baseImageUrl}/logos/airbnb-logo.jpg`,
    Expedia: `${baseImageUrl}/logos/expedia-logo.jpg`,
    "Hotels.com": `${baseImageUrl}/logos/hotels-logo.jpg`,
  };

  const [loadingLogos, setLoadingLogos] = useState({
    "Booking.com": false,
    airbnb: false,
    Expedia: false,
    "Hotels.com": false,
  });

  const trimAndLowercase = (str: string | null): string => {
    if (str === null) {
      return "";
    }

    if (str.endsWith(".com")) {
      str = str.slice(0, -4);
    }
    return str.toLowerCase();
  };

  // Function to call individual booking APIs
  const fetchFromProviders = async (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => {
    const providers = Object.keys(ProviderLookup);
    setLoadingProviders(true);

    const promises = providers.map(async (provider) => {
      setLoadingLogos((prev) => ({ ...prev, [provider]: true }));

      try {
        const sanitizedProvider: string = trimAndLowercase(provider);
        await axios.post(`/api/${sanitizedProvider}`, {
          params: searchParams,
        });
      } catch (err) {
        console.error(`Error calling ${provider} API:`, err);
        //Do Nothing...
      } finally {
        setLoadingLogos((prev) => ({ ...prev, [provider]: false }));
      }
    });

    // Wait for all promises to complete
    await Promise.all(promises);

    setLoadingProviders(false);
  };

  const callCacheApi = async (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }): Promise<AxiosResponse<any>> => {
    setLoadingCache(true);
    const response = await axios.get("/api/Cache", {
      params: {
        ...searchParams,
        pageSize: itemsPerPage,
        pageNumber: currentPage,
      },
    });
    setLoadingCache(false);
    return response;
  };

  // Function to fetch the main search results
  const fetchFromCache = async (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => {
    try {
      // Make the main GET request with pagination parameters
      const firstResponse = await callCacheApi(searchParams);

      if (firstResponse.data && firstResponse.data.results.length > 0) {
        setResults(firstResponse.data.results);
        setTotalPages(firstResponse.data.totalPages || 1);
      } else {
        // If no results, call the provider API to load the cache
        fetchFromProviders(searchParams);
        //Cache call
        const secondResponse = await callCacheApi(searchParams);
        setResults(secondResponse.data.results);
        setTotalPages(secondResponse.data.totalPages || 1);
        setCurrentPage(1); //Ensure you reset the pagination
      }
      setError("");
    } catch (err) {
      setError(
        "Une erreur est survenue lors de la récupération des résultats."
      );
      setResults([]);
    } finally {
      //Ensure loading popups are closed
      setLoadingCache(false);
      setLoadingProviders(false);
    }
  };

  // Fetch search results from the API
  const fetchResults = async (searchParams: {
    destination: string;
    checkIn: string;
    checkOut: string;
    adults: number;
    children: number;
    rooms: number;
    childAges: number[];
  }) => {
    // Only perform search if all required fields are populated
    if (
      searchParams.destination &&
      searchParams.checkIn &&
      searchParams.checkOut &&
      searchParams.adults &&
      searchParams.rooms
    ) {
      setSearchInitiated(true);
      // Then call the main GET API
      //TODO: Uncomment when api is ready
      //await fetchFromCache(searchParams);
    }
  };

  // Automatically fetch results if initial search parameters are provided
  useEffect(() => {
    // Scroll to top on load
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (
      initialSearchParams.destination &&
      initialSearchParams.checkIn &&
      initialSearchParams.checkOut &&
      initialSearchParams.adults &&
      initialSearchParams.rooms
    ) {
      fetchResults(initialSearchParams);
    }
  }, [initialSearchParams, currentPage]);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const encodedDestination = destination
    ? encodeURIComponent(destination.toLowerCase())
    : "";

  const destinationDisplayName = capitalizeDestinationName(destination);

  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>
          Hôtels, Séjours & Attractions à {destinationDisplayName} | Réservez
          avec SenReservations
        </title>
        <meta
          name="description"
          content={`Découvrez les meilleures offres d'hébergement à ${destinationDisplayName} avec SenReservations. Comparez les prix, trouvez les meilleures options et réservez votre séjour dès aujourd'hui.`}
        />
        <link
          rel="canonical"
          href={`${baseUrl}/sejours/${encodedDestination}`}
        />
        <link
          rel="alternate"
          href={`${baseUrl}/sejours/${encodedDestination}`}
          hrefLang="fr-SN"
        />
      </Helmet>
      {/* Main content */}

      <div className="min-h-screen flex flex-col">
        <div className="relative sticky top-0 border-b border-gray-200 bg-gray-100 w-full pt-6 pb-6 md:pb-0">
          <div className="max-w-7xl mx-auto [&>section]:pb-0">
            <StaySearchBar
              hideHeader
              onSearch={fetchResults}
              initialValues={initialSearchParams}
              isSearchResultPage={true}
            />
          </div>
        </div>

        {/* Loading Popup */}
        {loadingCache && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg text-center w-64">
              <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                <div className="bg-blue-600 h-2.5 rounded-full animate-pulse"></div>
              </div>
              <p className="text-gray-700">Chargement...</p>
            </div>
          </div>
        )}
        {loadingProviders && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg text-center">
              <h2 className="text-lg font-bold text-gray-800 mb-4">
                Recherche en cours...
              </h2>
              <div className="flex justify-center space-x-4 mt-4">
                {Object.entries(loadingLogos).map(([provider, isLoading]) => (
                  <div
                    key={provider}
                    className={`text-gray-500 ${
                      !isLoading ? "opacity-30" : "opacity-100"
                    }`}
                  >
                    {provider}
                  </div>
                ))}
              </div>
              <div className="w-full bg-gray-200 h-2 rounded mt-4">
                <div
                  className="bg-blue-600 h-2 rounded"
                  style={{
                    width: `${
                      Object.values(loadingLogos).filter((v) => !v).length * 25
                    }%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        )}

        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex-grow mt-4 md:mt-8">
          {/* Title for Search Results */}
          {results.length > 0 && (
            <h2 className="text-2xl font-bold text-gray-800 mb-4">
              Résultats pour les séjours à {destinationDisplayName}
            </h2>
          )}

          {/* Initial SEO Content if Search not Initiated */}
          <DestinationOverview
            searchInitiated={searchInitiated}
            destination={destination}
          />

          {/* Display Search Results */}
          {results.length > 0 ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-6">
              {results.map((result: SearchResultItem) => (
                <div
                  key={result.id}
                  className="border rounded-lg shadow-lg overflow-hidden bg-white"
                >
                  <img
                    src={result.imageUrl}
                    alt={result.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-bold text-gray-800 truncate">
                      {result.title}
                    </h3>
                    <p className="text-gray-500 text-sm">{result.location}</p>
                    <p className="text-blue-600 font-semibold text-lg">
                      {result.price} CFA
                    </p>
                    <p className="text-gray-500 text-xs line-clamp-2">
                      {result.shordesc}
                    </p>
                    <a
                      href={`/redirect?to=${encodeURIComponent(result.url)}`}
                      className="mt-2 bg-blue-600 text-white py-2 px-4 rounded hover:bg-blue-700 flex items-center justify-center space-x-2"
                      target="_blank"
                      rel="noopener"
                    >
                      Voir l’offre{" "}
                      <FaChevronRight className="ml-2 text-white" />
                      <span className="ml-2">{result.source}</span>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            searchInitiated && (
              <div className="p-6 bg-white border border-gray-300 rounded-md shadow-md text-center mt-6">
                <h2 className="text-lg font-semibold text-gray-800 mb-4">
                  Aucun résultat trouvé pour votre recherche.
                </h2>
                <p className="text-gray-600 mb-4">
                  Nous travaillons actuellement sur l'intégration complète avec
                  les grandes marques de réservation pour vous offrir les
                  meilleures offres. En attendant, vous pouvez visiter l'un de
                  nos partenaires pour trouver votre prochain séjour :
                </p>
                <div className="flex flex-wrap justify-center gap-4">
                  <a
                    href={`/redirect?provider=expedia&to=${process.env.REACT_APP_EXPEDIA_DEEP_LINK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center"
                  >
                    <img
                      src={`${baseImageUrl}/logos/expedia-logo.svg`}
                      alt="Expedia"
                      className="h-12 w-auto mb-2"
                    />
                    <span className="text-blue-600 hover:underline">
                      Expedia
                    </span>
                  </a>
                </div>
              </div>
            )
          )}
          {error && <p className="text-left text-red-500">{error}</p>}

          {/* Pagination Controls */}

          {totalPages > 1 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={(page) => setCurrentPage(page)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SearchResult;
