import React from "react";
import { Helmet } from "react-helmet-async";
import Stays from "../components/Stays";
import {
  DestinationProvider,
  useDestinations,
} from "../dataProviders/DestinationProvider";

const HomePageContent: React.FC = () => {
  const { senegalDestinations, internationalDestinations } = useDestinations();
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const generateDestinationSchema = (destination: {
    name: string;
    description: string;
    imageUrl: string;
  }) => ({
    "@context": "https://schema.org",
    "@type": "Place",
    name: destination.name,
    description: destination.description,
    image: destination.imageUrl,
  });

  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    url: `${baseUrl}`,
    name: "SenReservations",
  };

  const combinedDestinationSchema = [
    websiteSchema,
    ...senegalDestinations
      .concat(internationalDestinations)
      .map(generateDestinationSchema),
  ];

  return (
    <>
      {/* Page meta info */}
      <Helmet>
        <title>
          SenReservations | Votre Plateforme de Réservation : Hôtels,
          Appartements, Vols et Plus au Sénégal et à l'Étranger
        </title>
        <meta
          name="description"
          content="Découvrez les meilleures offres d'hébergement, de vols, et plus encore avec SenReservations. Comparez les plus grands fournisseurs pour trouver l'option parfaite pour votre prochain voyage."
        />
        <link rel="canonical" href={`${baseUrl}/`} />
        <link rel="alternate" href={`${baseUrl}/`} hrefLang="fr-SN" />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
        <script type="application/ld+json">
          {JSON.stringify(combinedDestinationSchema)}
        </script>
      </Helmet>
      {/* Main content */}
      <Stays />
    </>
  );
};

// Wrap the HomePageContent with DestinationProvider
const HomePage: React.FC = () => (
  <DestinationProvider>
    <HomePageContent />
  </DestinationProvider>
);

export default HomePage;
