import React from "react";
import StaySearchBar from "./StaySearchBar";
import Destinations from "./Destinations";
import ProviderLogos from "./ProviderLogos";

const Stays: React.FC = () => {
  return (
    <>
      {/* Stay Search Bar Section */}
      <section className="w-full bg-gray-50 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <StaySearchBar />
        </div>
      </section>

      {/* Provider Logos Section */}
      {/* 
      <ProviderLogos />
      */}

      {/* Destinations Section */}
      <section className="w-full bg-gray-100 py-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <Destinations />
        </div>
      </section>
    </>
  );
};

export default Stays;
